import _ from "underscore";

const state = {
  driverDocuments: [],
};

const getters = {
  driverDocuments: state => state.driverDocuments,
};

const actions = {
  setDriverDocuments: ({
    commit
  }, data) => {
    commit("setDriverDocuments", data);
  },
  updateDriverDocument: ({
    commit
  }, data) => {
    commit("updateDriverDocument", data);
  },
  addDriverDocument: ({
    commit
  }, chain) => {
    commit('addDriverDocument', chain)
  },
};

const mutations = {
  setDriverDocuments(state, data) {
    state.driverDocuments = data;
  },
  updateDriverDocument(state, data) {
    state.driverDocuments = _.map(state.driverDocuments, o => {
      if (o._id === data._id) return data;
      return o;
    });
  },
  addDriverDocument(state, data) {
    state.driverDocuments = state.driverDocuments.concat([data])
  },
};

export default {
  state,
  getters,
  actions,
  mutations
};