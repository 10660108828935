<template lang="pug">
v-card
  .text-center
    .overline {{ title }}
    .title {{ totalEarning | currency }}
    .caption.text--secondary Total Earnings
  v-card-text
    StandardBarChart(:input='chartInput')
  v-divider
  v-simple-table
    tbody
      tr
        td Delivery Pay
        td.text-right {{ totalFee | currency }}
      tr
        td Tip
        td.text-right {{ totalTip | currency }}
      tr(v-if='totalReward > 0')
        td Reward
        td.text-right {{ totalReward | currency }}
</template>

<script>
import _ from "underscore";
import StandardBarChart from "@/components/basic/StandardBarChart";

export default {
  props: ["result", "title"],
  components: { StandardBarChart },
  data() {
    return {
      labels: ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"],
    };
  },
  computed: {
    totalEarning() {
      return _.reduce(
        this.result,
        (memo, item) => {
          return memo + item.earning;
        },
        0
      );
    },
    totalFee() {
      return _.reduce(
        this.result,
        (memo, item) => {
          return memo + item.fee;
        },
        0
      );
    },
    totalTip() {
      return _.reduce(
        this.result,
        (memo, item) => {
          return memo + item.tip;
        },
        0
      );
    },
    totalReward() {
      return _.reduce(
        this.result,
        (memo, item) => {
          return memo + item.reward;
        },
        0
      );
    },
    chartInput() {
      const values = _.map(_.range(1, 8), day => {
        const found = _.find(this.result, o => o.day == day);
        if (found) return found.earning;
        else return 0;
      });
      return {
        title: "",
        labels: this.labels,
        values: values,
        isCurrency: true,
      };
    },
  },
};
</script>
