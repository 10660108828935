<template lang="pug">
div
  v-card.my-2(v-if="schedule", outlined)
    v-toolbar(flat, dense)
      .subtitle-2 {{ today }}
      v-spacer
      v-btn.text-capitalize(
        rounded,
        outlined,
        x-small,
        color="secondary",
        @click="edit()"
      ) Edit
        v-icon(small, right) mdi-pencil
    v-card-text.pt-0
      .body-2 {{ schedule | driverSchedule }}
  Form
</template>

<script>
import moment from "moment";
import _ from "underscore";
import { EventBus } from "@/event-bus.js";
import Form from "@/components/Account/Schedule/Form";
import { mapActions, mapGetters } from "vuex";

export default {
  components: { Form },
  computed: {
    ...mapGetters(["driverSchedules"]),
    today() {
      return moment().format("dddd M/D");
    },
    schedule() {
      const user = this.$auth.user();
      if (!user) return;
      const date = moment().format("YYYY-MM-DD");
      return _.find(
        this.driverSchedules,
        (o) => o.date == date && o.user == user._id
      );
    },
  },
  methods: {
    ...mapActions(["setDriverSchedules"]),
    async load() {
      const user = this.$auth.user();
      if (!user) return;
      const date = moment().format("YYYY-MM-DD");
      const params = { criteria: { user: user._id, date: date } };
      const res = await this.axios.post("/driver/schedules/retrieve", params);
      if (res.data) {
        this.setDriverSchedules([res.data]);
      } else {
        const range = _.range(480, 1320, 30);
        const blocks = _.map(range, (mm) => {
          return { mm: mm, status: false };
        });
        const data = {
          user: user._id,
          date: date,
          status: true,
          blocks: blocks,
        };
        this.setDriverSchedules([data]);
      }
    },
    edit() {
      if (!this.schedule) return;
      EventBus.$emit("edit-driver-schedule", this.schedule);
    },
  },
  mounted() {
    this.load();
  },
};
</script>