<template lang="pug">
v-card(v-if="delivOrder", outlined)
  TopBar(:delivOrder="delivOrder")
  Seller(:delivOrder="delivOrder")
  v-divider
  Customer(:delivOrder="delivOrder")
  v-divider
  Time(:delivOrder="delivOrder")
  v-divider
  Payments(:delivOrder="delivOrder")
  .pa-2
    .body-2.green--text(v-if="delivOrder.type == 1") This is NOT a selflane order. Manager assigned.
    Accept(:delivOrder="delivOrder", v-if="!accepted")
    div(v-else)
      PickUpAction(:delivOrder="delivOrder")
      DeliveredAction(:delivOrder="delivOrder")
  v-img.mx-3.mb-3(:src="deliv_photo", height="100", width="100")
</template>

<script>
import { mapActions } from "vuex";
import TopBar from "./TopBar";
import Seller from "./Seller";
import Customer from "./Customer";
import Time from "./Time";
import Payments from "./Payments";
import Accept from "./Accept/index";
import PickUpAction from "./PickUpAction";
import DeliveredAction from "./DeliveredAction";

export default {
  props: ["delivOrder"],
  components: {
    TopBar,
    Seller,
    Customer,
    Time,
    Payments,
    Accept,
    PickUpAction,
    DeliveredAction,
  },
  computed: {
    accepted() {
      return (
        this.delivOrder &&
        this.delivOrder.driver &&
        this.delivOrder.driver.accept > 0
      );
    },
    deliv_photo() {
      if (this.delivOrder && this.delivOrder.deliv_photo)
        return "https://media.selflane.com/" + this.delivOrder.deliv_photo;
      return null;
    },
  },
  methods: {
    ...mapActions(["updateDelivOrder"]),
    handleFulfill() {
      confirm("Yes, I delivered this order") && this.fulfill();
    },
    async fulfill() {
      if (!this.delivOrder) return;
      const params = { id: this.delivOrder._id };
      try {
        const res = await this.axios.post("/delivOrders/fulfill", params);
        this.updateDelivOrder(res.data);
      } catch (err) {
        if (err.response) alert(err.response.data);
      }
    },
  },
};
</script>
