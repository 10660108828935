<template lang="pug">
div
  .d-flex.flex-row.align-center.sticky
    v-btn.text-capitalize(
      outlined,
      small,
      rounded,
      color="secondary",
      @click="load()",
      :loading="loading"
    ) Reload
      v-icon(right, small) mdi-sync
    v-spacer
    v-btn-toggle(v-model="open", dense, rounded, color="secondary")
      v-btn.text-capitalize(
        small,
        v-for="(item, index) in openItems",
        :key="index",
        :value="item.value"
      )
        span.mr-1 {{ item.title }}
        span(v-if="item.value") {{ openCount }}
        span(v-else) {{ closedCount }}
  ListCards(:items="selectedOrders")
</template>
<script>
import { mapGetters, mapActions } from "vuex";
import moment from "moment";
import ListCards from "./DelivOrderCard/ListCards";
import _ from "underscore";

export default {
  components: { ListCards },
  data: () => ({
    driver: null,
    loading: false,
    open: true,
    openItems: [
      { title: "Open", value: true },
      { title: "Delivered", value: false },
    ],
  }),
  computed: {
    ...mapGetters(["delivOrders"]),
    selectedOrders() {
      if (this.open) {
        return _.chain(this.delivOrders)
          .reject((o) => o.status == 4)
          .sortBy("needed")
          .value();
      } else {
        return _.chain(this.delivOrders)
          .filter((o) => o.status == 4)
          .sortBy("needed")
          .value();
      }
    },
    openCount() {
      return _.filter(this.delivOrders, (o) => o.status != 4).length;
    },
    closedCount() {
      return _.filter(this.delivOrders, (o) => o.status == 4).length;
    },
  },
  methods: {
    ...mapActions(["setDelivOrders", "setBizAccounts"]),
    async load() {
      if (!this.driver) this.refresh();
      this.loading = true;
      const begin = moment().startOf("day").unix() * 1000;
      const end = moment().endOf("day").unix() * 1000;
      const params = {
        criteria: {
          "driver.id": this.driver._id,
          needed: { $gte: begin, $lte: end },
          status: { $gte: 0 },
        },
      };
      const res = await this.axios.post("/delivOrders/list", params);
      this.setDelivOrders(res.data);
      this.loading = false;
      this.loadBizs();
    },
    async loadBizs() {
      const bizIds = _.pluck(this.delivOrders, "biz");
      if (!bizIds || !bizIds.length) return;
      const params = {
        criteria: { _id: { $in: bizIds } },
        select: "name address phones",
      };
      const res = await this.axios.post("/bizs/list", params);
      this.setBizAccounts(res.data);
    },
    async refresh() {
      const user = this.$auth.user();
      if (!user || !user._id) {
        alert("Error loading user. Please log in again");
        return;
      }
      const params = { criteria: { user: user._id } };
      const res = await this.axios.post("/driver/accounts/retrieve", params);
      this.driver = res.data;
      if (this.driver) this.load();
    },
  },
  mounted() {
    this.refresh();
  },
};
</script>

<style>
table {
  width: 100%;
}
table th {
  text-align: left;
  width: 80px;
}
table td {
  min-width: 45px;
}
.sticky {
  position: -webkit-sticky;
  position: sticky;
  top: 60px;
  z-index: 4;
  background: #F0F2F5;
  border-bottom: 1px solid #E7E7E7;
  padding-top: 12px;
  padding-bottom: 12px;
  margin-bottom: 12px
}
</style>
