const state = {
  delivUserList: [],
  deliv: null,
  delivRole: null
}

const getters = {
  delivUserList: state => state.delivUserList,
  deliv: state => state.deliv,
  delivRole: state => state.delivRole
}

const actions = {
  SetDelivUserList: ({
    commit
  }, delivUserList) => {
    commit('SetDelivUserList', delivUserList)
  },
  setDeliv: ({
    commit
  }, item) => {
    commit('setDeliv', item)
  },
  setDelivRole: ({
    commit
  }, role) => {
    commit('setDelivRole', role)
  }
}

const mutations = {
  SetDelivUserList(state, delivUserList) {
    state.delivUserList = delivUserList
    if (delivUserList && delivUserList.length > 0) {
      this.dispatch('setDeliv', delivUserList[0].deliv)
      this.dispatch('setDelivRole', delivUserList[0].role)
    }
  },
  setDeliv(state, item) {
    state.deliv = item
  },
  setDelivRole(state, role) {
    state.delivRole = role
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}