/// Select a week range, use isoWeek -- starts on Monday
<template lang="pug">
v-card.px-5.py-2(outlined, max-width="280")
  v-row(align="center", justify="center")
    v-btn(icon, small, @click="previous()", title="previous")
      v-icon mdi-menu-left
    v-menu(offset-y)
      template(v-slot:activator="{ on }")
        v-btn.px-1(text, v-on="on", title="open date selector") {{ begin | YYYYMMMD }} - {{ end | MMMD }}
      v-date-picker(
        v-model="begin",
        :allowed-dates="allowedDates",
        :max="maxDate"
      )
    v-btn(icon, small, @click="next()", title="next")
      v-icon mdi-menu-right
</template>

<script>
import moment from "moment";
export default {
  name: "weekselector",
  data() {
    return {
      begin: moment().startOf("isoWeek").format("YYYY-MM-DD"),
    };
  },
  watch: {
    // send whenever begin is edited, either by clicking next/previous buttons or by selecting date
    begin() {
      this.send();
    },
  },
  filters: {
    YYYYMMMD: function (m) {
      return moment(m).format("YYYY MMM D");
    },
    MMMD: function (m) {
      return moment(m).format("MMM D");
    },
  },
  computed: {
    end() {
      return moment(this.begin).endOf("isoWeek").format("YYYY-MM-DD");
    },
    maxDate() {
      return moment().format("YYYY-MM-DD");
    },
  },
  methods: {
    /// only allow choose Mondays
    allowedDates(val) {
      return moment(val).day() == 1;
    },
    next() {
      this.begin = moment(this.begin).add(1, "weeks").format("YYYY-MM-DD");
    },
    previous() {
      this.begin = moment(this.begin).subtract(1, "weeks").format("YYYY-MM-DD");
    },
    send() {
      const beginUnix = moment(this.begin).unix() * 1000;
      const endUnix = moment(this.begin).endOf("isoWeek").unix() * 1000;
      this.$emit("select", {
        begin: beginUnix,
        end: endUnix,
      });
    },
  },
  mounted() {
    this.send();
  },
};
</script>
