import { render, staticRenderFns } from "./DeliveredAction.vue?vue&type=template&id=6d5ddcd8&scoped=true&lang=pug&"
import script from "./DeliveredAction.vue?vue&type=script&lang=js&"
export * from "./DeliveredAction.vue?vue&type=script&lang=js&"
import style0 from "./DeliveredAction.vue?vue&type=style&index=0&id=6d5ddcd8&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6d5ddcd8",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VAlert } from 'vuetify/lib/components/VAlert';
import { VBottomSheet } from 'vuetify/lib/components/VBottomSheet';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VSheet } from 'vuetify/lib/components/VSheet';
installComponents(component, {VAlert,VBottomSheet,VBtn,VSheet})
