const state = {
  driverIds: [],
}

const getters = {
  driverIds: state => state.driverIds,
}

const actions = {
  SetDriverIds: ({
    commit
  }, ids) => {
    commit('SetDriverIds', ids)
  }
}

const mutations = {
  SetDriverIds(state, ids) {
    state.driverIds = ids
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}