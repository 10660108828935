<template lang="pug">
v-dialog(v-model="dialog", width="350")
  v-card(v-if="driver")
    v-toolbar(flat, dense)
      .subtitle-2 Edit Profile
    v-card-text
      v-form(@submit.prevent="submit")
        v-text-field(
          v-model="name",
          label="Name",
          :error-messages="nameErrors",
          @blur="$v.name.$touch()",
          :disabled="disable_name"
        )
        v-text-field(
          v-model="phone",
          v-mask="phoneMask",
          label="Phone",
          :error-messages="phoneErrors",
          @blur="$v.phone.$touch()"
        )
        v-select(
          v-model="zones",
          :items="countryZones",
          label="Working Zones",
          multiple
        )
        v-btn(
          block,
          color="secondary",
          type="submit",
          :disabled="$v.$invalid",
          :loading="loading"
        ) Save
</template>

<script>
import { mask } from "vue-the-mask";
import { validationMixin } from "vuelidate";
import { required } from "vuelidate/lib/validators";
import { EventBus } from "@/event-bus.js";
import { mapActions, mapGetters } from "vuex";
import _ from "underscore";

export default {
  name: "edit-driver",
  directives: { mask },
  mixins: [validationMixin],
  validations: {
    name: { required },
    phone: { required },
  },
  data() {
    return {
      dialog: false,
      loading: false,
      name: "",
      phone: "",
      phoneMask: "(###) ###-####",
      zones: [],
    };
  },
  computed: {
    ...mapGetters(["driver", "geoServiceZones"]),
    countryZones() {
      if (!this.driver) return [];
      const country = this.driver.country || "US";
      return _.chain(this.geoServiceZones)
        .filter((o) => o.country == country)
        .sortBy("zoneName")
        .map((o) => {
          return {
            value: o.zoneName,
            text: o.zoneDetail || o.zoneName,
          };
        })
        .value();
    },
    disable_name() {
      return this.driver.deliv && this.driver.deliv.length > 0;
    },
    nameErrors() {
      const errors = [];
      if (!this.$v.name.$dirty) return errors;
      !this.$v.name.required && errors.push("Name is required");
      return errors;
    },
    phoneErrors() {
      const errors = [];
      if (!this.$v.phone.$dirty) return errors;
      !this.$v.phone.required && errors.push("Phone is required");
      return errors;
    },
  },
  methods: {
    ...mapActions(["setDriver"]),
    open() {
      if (!this.driver) return;
      this.dialog = true;
      this.name = this.driver.name;
      this.phone = this.driver.phone;
      this.zones = this.driver.zones;
    },
    async submit() {
      if (!this.driver) return;
      const options = {
        criteria: { _id: this.driver._id },
        action: {
          $set: {
            name: this.name.trim(),
            phone: this.phone.replace(/[^\d]/g, ""),
            zones: this.zones,
          },
        },
      };
      this.loading = true;
      try {
        const res = await this.axios.post("/driver/accounts/update", options);
        this.setDriver(res.data);
        this.dialog = false;
      } catch (err) {
        alert(err.response.data);
      }
      this.loading = false;
    },
  },
  mounted() {
    EventBus.$on("edit-driver", this.open);
  },
  destroyed() {
    EventBus.$off("edit-driver");
  },
};
</script>
