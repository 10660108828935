import _ from 'underscore'

const state = {
  orders: []
}

const getters = {
  orders: state => state.orders
}

const actions = {
  setOrders: ({ commit }, orders) => { commit('setOrders', orders) },
  updateOrder: ({ commit }, order) => { commit('updateOrder', order) }
}

const mutations = {
  setOrders (state, orders) {
    state.orders = orders
  },
  updateOrder (state, order) {
    state.orders = _.map(state.orders, o => {
      if (o._id === order._id) return order
      return o
    })
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}
