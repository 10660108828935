import Vue from "vue";
import Router from "vue-router";
import Home from '@/components/Home/index.vue'
import Orders from '@/components/Orders/index.vue'
import Login from "@/components/User/Login/index.vue";
import ResetPWD from "@/components/User/Login/ResetPWD.vue";
import accountRoute from '@/components/Account/route'
import footerRoute from '@/components/Footer/route'

Vue.use(Router);

const baseRoutes = [{
  path: "/",
  name: "Home",
  component: Home
}, {
  path: '/orders',
  component: Orders,
  meta: {
    auth: true
  }
}, {
  path: "/login",
  component: Login
}, {
  path: "/resetPWD",
  component: ResetPWD
}, {
  path: "*",
  redirect: "/"
}]
const routes = baseRoutes.concat(accountRoute, footerRoute)

export default new Router({
  mode: "history",
  linkActiveClass: "is-active",
  scrollBehavior: () => ({
    y: 0
  }),
  routes: routes
});