<template lang="pug">
.py-4.mx-auto(style="max-width: 400px; width: 100%")
  .deep-orange--text.darken-3
    h3.body-2 Welcome to
    h3.title.font-weight-bold Selflane
  .grey--text.text--darken-1.mt-2 We help local restaurants to serve better

  v-row(align="center", justify="center")
    #google-signin.my-3
    small simple and secure with Google signin.
      a(@click="showWhyGoogle") why?
  v-row.my-4
    v-col
      v-divider.grey
    .grey--text.text--darken-2 OR
    v-col
      v-divider.grey
  v-card
    v-card-text
      v-form(@submit.prevent="submit")
        v-text-field(
          label="E-mail",
          v-model.trim="email",
          :error-messages="emailErrors",
          @blur="$v.email.$touch()",
          required,
          prepend-icon="email"
        )
        v-text-field(
          name="password",
          type="password",
          label="Password",
          v-model="password",
          :error-messages="passwordErrors",
          required,
          @blur="$v.password.$touch()",
          prepend-icon="lock_outline"
        )
        v-text-field(
          name="passwordRep",
          type="password",
          label="Repeat Password",
          v-model="passwordRep",
          :error-messages="passwordRepErrors",
          required,
          @blur="$v.passwordRep.$touch()",
          prepend-icon="lock_outline",
          v-if="!isLogin"
        )
        v-btn.white--text.mt-4(
          block,
          :disabled="$v.$invalid",
          type="submit",
          color="indigo",
          :loading="loading"
        )
          span(v-if="isLogin") Sign in
          span(v-else) Sign up
      span.caption.red--text(v-if="error") {{ error }}
  v-toolbar.my-4
    v-btn(text, color="brown", @click="isLogin = !isLogin; error = null")
      span(v-if="isLogin") sign up
      span(v-else) sign in
    v-spacer
    v-btn(text, color="brown", @click="resetPWD()") forget password
  v-dialog(v-model="codeDialog", persistent, max-width="300")
    v-card
      v-card-text
        v-form(@submit.prevent="verify")
          v-text-field(
            label="Verification Code",
            v-model="verificationCode",
            required,
            mask="######",
            persistent-hint,
            hint="6 digit code sent by email",
            autofocus
          )
          v-btn.white--text(
            block,
            type="submit",
            :disabled="verifying",
            :loading="verifying",
            color="indigo"
          ) submit
        span.caption.red--text(v-if="error") {{ error }}
  v-snackbar(color="red", v-model="snackbar") {{ snackbarText }}
    v-btn(dark, text, @click.native="snackbar = false") Close
</template>

<script>
import { validationMixin } from "vuelidate";
import { required, email, minLength, sameAs } from "vuelidate/lib/validators";
import { EventBus } from "@/event-bus.js";

export default {
  mixins: [validationMixin],
  validations() {
    if (this.isLogin) {
      return { email: { required, email }, password: { required } };
    } else {
      return {
        email: { required, email },
        password: { required, minLength: minLength(6) },
        passwordRep: {
          sameAsPassword: sameAs("password"),
        },
      };
    }
  },
  data() {
    return {
      isLogin: true,
      email: "",
      password: "",
      passwordRep: "",
      loading: false,
      error: null,
      codeDialog: false,
      verificationCode: "",
      snackbar: false,
      snackbarText: "",
      verifying: false,
    };
  },
  computed: {
    emailErrors() {
      const errors = [];
      if (!this.$v.email.$dirty) return errors;
      !this.$v.email.email && errors.push("Must be valid e-mail");
      !this.$v.email.required && errors.push("E-mail is required");
      return errors;
    },
    passwordErrors() {
      const errors = [];
      if (!this.$v.password.$dirty) return errors;
      !this.$v.password.required && errors.push("Password is required");
      if (!this.isLogin) {
        !this.$v.password.minLength &&
          errors.push("Password should be at least 6 characters.");
      }
      return errors;
    },
    passwordRepErrors() {
      const errors = [];
      if (!this.$v.passwordRep.$dirty) return errors;
      !this.$v.passwordRep.sameAsPassword &&
        errors.push("Repeat password is not the same");
      return errors;
    },
  },
  methods: {
    onGoogleSignin(googleUser) {
      const tokenId = googleUser.getAuthResponse().id_token;
      const url = "/auth/google/cftoken" + "?id_token=" + tokenId;
      let redirect = { name: "Home" };
      if (this.$auth.redirect()) {
        redirect = this.$auth.redirect().from;
      }
      this.$auth.login({
        url: url,
        method: "GET",
        fetchUser: true,
        redirect: redirect,
        rememberMe: true,
        success() {
          EventBus.$emit("login");
        },
      });
    },
    showWhyGoogle() {
      alert(
        "We ask for your email address to setup an account. To make sure you own the email address, you would need to verify it via an email sent by us. Using Google sign in bypasses this verification process. You also don't need setup a new password for login. We don't take any information from your Google account other than the email address and your public name."
      );
    },
    submit() {
      if (this.isLogin) {
        this.login();
      } else {
        this.signup();
      }
    },
    login() {
      this.loading = true;
      this.error = null;
      let redirect = { name: "Home" };
      if (this.$auth.redirect()) {
        redirect = this.$auth.redirect().from;
      }
      this.$auth.login({
        url: "/users/login",
        method: "POST",
        fetchUser: true,
        headers: { "Content-Type": "application/json" },
        data: { email: this.email, password: this.password },
        redirect: redirect,
        rememberMe: true,
        success() {
          this.loading = false;
          EventBus.$emit("login");
        },
        error(err) {
          this.loading = false;
          if (err.response) {
            this.error = err.response.data;
          } else {
            this.error = "Failed";
          }
        },
      });
    },
    signup() {
      this.loading = true;
      const params = { email: this.email, password: this.password };
      this.axios
        .post("/users/create", params)
        .then(() => {
          this.loading = false;
          this.codeDialog = true;
        })
        .catch((err) => {
          this.loading = false;
          if (err.response) this.error = err.response.data;
        });
    },
    verify() {
      this.error = null;
      this.verifying = true;
      const url =
        "/users/verify?a=" + this.email + "&b=" + this.verificationCode;
      this.axios
        .get(url)
        .then(() => {
          this.verifying = false;
          this.codeDialog = false;
          this.login();
        })
        .catch((err) => {
          this.verifying = false;
          if (err.response) this.error = err.response.data;
        });
    },
    resetPWD() {
      this.$router.push("/resetPWD");
    },
    loadGoogleBtn() {
      const clientId = process.env.VUE_APP_GOOGLE_CLIENT;
      const onGoogleSignin = this.onGoogleSignin;
      // eslint-disable-next-line
      gapi.load("auth2", initAuth2);
      function initAuth2() {
        // eslint-disable-next-line
        gapi.auth2.init({ client_id: clientId }).then(() => {
          // eslint-disable-next-line
          gapi.signin2.render("google-signin", {
            width: 300,
            theme: "dark",
            longtitle: true,
            onsuccess: onGoogleSignin,
          });
        });
      }
    },
  },
  mounted() {
    this.loadGoogleBtn();
  },
};
</script>
