<template lang="pug">
div
  Table
  Form
</template>

<script>
import Table from "./Table";
import Form from "./Form";

export default {
  components: { Table, Form },
};
</script>