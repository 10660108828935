<template lang="pug">
v-bottom-sheet(v-model="sheet", v-if="visible")
  template(v-slot:activator="{ on }")
    v-btn.text-notransform(block, color="secondary", v-on="on", @click="open") Yes, I Delivered
  v-sheet.text-center.pa-3
    v-alert(type="error", v-if="isTooClose") You just clicked Picked Up
    .subtitle-2 Please include the package and a good portion of the destination in the picture.
    .camera-box.my-3
      video(
        ref="camera",
        :width="canvasWidth",
        :height="canvasHeight",
        autoplay="",
        playsinline
      )
      canvas#photoTaken(
        v-show="false",
        ref="canvas",
        :width="canvasWidth",
        :height="canvasHeight"
      )
    v-btn.my-3text-notransform(
      color="secondary",
      block,
      @click="capture",
      :loading="loading",
      :disabled="loading_camera",
      v-if="isCameraOpen"
    ) Confirm Delivered
    v-btn.my-3.text-notransform(
      color="secondary",
      @click="fulfill()",
      :loading="loading",
      :disabled="loading_camera",
      v-else
    ) Confirm Delivered
</template>

<script>
import { mapActions } from "vuex";
import moment from "moment";

export default {
  props: ["delivOrder"],
  data() {
    return {
      sheet: false,
      loading: false,
      isCameraOpen: false,
      canvasHeight: 300,
      canvasWidth: 300,
      loading_camera: false,
      items: [],
      location: null,
    };
  },
  computed: {
    visible() {
      return (
        this.delivOrder &&
        this.delivOrder.status >= 0 &&
        this.delivOrder.pickupTime &&
        !this.delivOrder.deliverTime
      );
    },
    isTooClose() {
      if (this.delivOrder.pickupTime) {
        const diff = (Date.now() - this.delivOrder.pickupTime) / 1000 / 60; // in munites
        return diff < 2;
      }
      return false;
    },
  },
  watch: {
    sheet(val) {
      if (!val) this.stopCameraStream();
    },
  },
  methods: {
    ...mapActions(["updateDelivOrder"]),
    open() {
      this.startCameraStream();
      this.refreshLocation();
    },
    async fulfill(dataUrl) {
      if (!this.delivOrder) return;
      this.loading = true;
      let formData = new FormData();
      formData.append("id", this.delivOrder._id);
      if (this.location) {
        formData.append("deliv_location", JSON.stringify(this.location));
      }
      if (dataUrl) {
        const file_name = this.delivOrder._id + "_" + moment().unix() + ".jpg";
        const file = this.dataURLtoFile(dataUrl, file_name);
        formData.append("file_type", "image/jpeg");
        formData.append("file", file);
      }
      try {
        const res = await this.axios.post("/delivOrders/fulfill", formData);
        this.updateDelivOrder(res.data);
        this.sheet = false;
      } catch (err) {
        if (err.response) alert(err.response.data);
      }
      this.loading = false;
    },
    dataURLtoFile(dataURL, filename) {
      let arr = dataURL.split(","),
        mime = arr[0].match(/:(.*?);/)[1],
        bstr = atob(arr[1]),
        n = bstr.length,
        u8arr = new Uint8Array(n);
      while (n--) {
        u8arr[n] = bstr.charCodeAt(n);
      }
      return new File([u8arr], filename, { type: mime });
    },
    refreshLocation() {
      if (navigator && navigator.geolocation) {
        navigator.geolocation.getCurrentPosition((position) => {
          if (position && position.coords) {
            this.location = {
              lat: position.coords.latitude,
              lng: position.coords.longitude,
            };
          }
        });
      }
    },
    startCameraStream() {
      this.isCameraOpen = true;
      this.loading_camera = true;
      const constraints = (window.constraints = {
        audio: false,
        video: {
          facingMode: { ideal: "environment" },
        },
      });
      navigator.mediaDevices
        .getUserMedia(constraints)
        .then((stream) => {
          this.$refs.camera.srcObject = stream;
          this.loading_camera = false;
        })
        .catch(() => {
          this.loading_camera = false;
        });
    },
    stopCameraStream() {
      this.isCameraOpen = false;
      let tracks = this.$refs.camera.srcObject.getTracks();
      tracks.forEach((track) => {
        track.stop();
      });
    },
    capture() {
      const context = this.$refs.canvas.getContext("2d");
      context.drawImage(
        this.$refs.camera,
        0,
        0,
        this.canvasWidth,
        this.canvasHeight
      );
      const dataUrl = this.$refs.canvas
        .toDataURL("image/jpeg")
        .replace("image/jpeg", "image/octet-stream");
      this.isCameraOpen = false;
      this.stopCameraStream();
      this.fulfill(dataUrl);
    },
  },
};
</script>

<style scoped>
.camera-box {
  border: 1px dashed #d6d6d6;
  border-radius: 6px;
  margin-left: auto;
  margin-right: auto;
}
</style>