<template lang="pug">
v-alert(type="error", v-if="!permission") Please turn on location sharing. Share location allow us send you orders quickly.
</template>

<script>
import { mapActions, mapGetters } from "vuex";

export default {
  data() {
    return {
      location: null,
      permission: true,
    };
  },
  computed: {
    ...mapGetters(["driver"]),
  },
  methods: {
    ...mapActions(["setDriver"]),
    async updateLastLocation(last_location) {
      if (!this.$auth.user()) return;
      const userId = this.$auth.user()._id;
      const params = {
        criteria: { user: userId },
        action: { $set: { last_location: last_location } },
      };
      const res = await this.axios.post("/driver/accounts/update", params);
      this.setDriver(res.data);
    },
    refresh() {
      const updateLastLocation = this.updateLastLocation;
      if (navigator && navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(
          (position) => {
            this.permission = true;
            if (position && position.coords) {
              const last_location = {
                lat: position.coords.latitude,
                lng: position.coords.longitude,
                time: Date.now(),
              };
              this.location = last_location;
              updateLastLocation(last_location);
            }
          },
          () => {
            this.permission = false;
          }
        );
      }
    },
  },
  mounted() {
    const refresh = this.refresh;
    refresh(); // fire immediately
    this.refreshLoop = setInterval(() => {
      refresh();
    }, 300000);
  },
  beforeDestroy() {
    clearInterval(this.refreshLoop);
  },
};
</script>