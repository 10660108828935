import Main from './index.vue'
import Week from './Week/index.vue'
import Documents from './Documents/index.vue'
import Schedule from './Schedule/index.vue'
import Payments from './Payments/index.vue'
import Payouts from './Payouts/index.vue'
import Tax from './Tax/index.vue'

export default [{
  path: '/account',
  component: Main,
  meta: {
    auth: true
  },
  children: [{
    path: '',
    redirect: 'week'
  }, {
    path: 'week',
    component: Week
  }, {
    path: 'documents',
    component: Documents
  }, {
    path: 'schedule',
    component: Schedule
  }, {
    path: 'payments',
    component: Payments
  }, {
    path: 'payouts',
    component: Payouts
  }, {
    path: 'tax',
    component: Tax
  }]
}]