<template lang="pug">
div
  .d-flex.flex-row.justify-end.align-center
    .subtitle-2 Total: {{ (total / 60) | number(1) }} hrs
    v-btn(icon, @click="previous()")
      v-icon mdi-chevron-left
    v-btn(icon, @click="next()")
      v-icon mdi-chevron-right
  v-simple-table
    thead
      tr
        th Date
        th.text-right Schedule
    tbody
      tr(
        v-for="(item, index) in itemsByDate",
        :key="index + 'date'",
        role="button",
        @click="edit(item.date)"
      )
        td {{ item.date | datetime('ddd M/D') }}
        td.text-right {{ item.schedule | driverSchedule }}
</template>

<script>
import moment from "moment";
import _ from "underscore";
import { mapGetters, mapActions } from "vuex";
import { EventBus } from "@/event-bus.js";

export default {
  data() {
    return {
      startStep: 0,
    };
  },
  computed: {
    ...mapGetters(["driverSchedules"]),
    dates() {
      const range = _.range(0, 7);
      const result = _.map(range, (o) => {
        const step = this.startStep + o;
        return moment().add(step, "d").format("YYYY-MM-DD");
      });
      return result;
    },
    itemsByDate() {
      return _.map(this.dates, (o) => {
        const found = _.find(
          this.driverSchedules,
          (schedule) => schedule.date == o
        );
        return {
          date: o,
          schedule: found,
        };
      });
    },
    total() {
      const blocks = _.chain(this.driverSchedules)
        .filter((o) => o.status)
        .pluck("blocks")
        .flatten()
        .filter((o) => o.status)
        .value();
      if (blocks) return blocks.length * 30;
      return 0;
    },
  },
  methods: {
    ...mapActions(["setDriverSchedules"]),
    previous() {
      this.startStep -= 7;
      if (this.startStep < 0) this.startStep = 0;
      this.load();
    },
    next() {
      this.startStep += 7;
      this.load();
    },
    edit(date) {
      const schedule = _.find(this.driverSchedules, (o) => o.date == date);
      if (schedule) {
        EventBus.$emit("edit-driver-schedule", schedule);
      } else {
        const user = this.$auth.user();
        if (!user) return;
        const range = _.range(480, 1320, 30);
        const data = {
          user: user._id,
          date: date,
          status: true,
          blocks: _.map(range, (mm) => {
            return {
              mm: mm,
              status: false,
            };
          }),
        };
        EventBus.$emit("edit-driver-schedule", data);
      }
    },
    async load() {
      const user = this.$auth.user();
      if (!user) return;
      const params = {
        criteria: {
          user: user._id,
          date: { $in: this.dates },
        },
      };
      const res = await this.axios.post("/driver/schedules/list", params);
      this.setDriverSchedules(res.data);
    },
  },
  mounted() {
    this.load();
  },
};
</script>
