<template lang="pug">
div(v-if='!loading')
  Account
  Persons(v-if='stripeAccount && stripeAccount.business_type=="company"')
  Banks(v-if='stripeAccount')
  Balance(v-if='stripeAccount')
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import Account from "./Account/index";
import Persons from "./Persons/index";
import Banks from "./Banks/index";
import Balance from "./Balance/index";

export default {
  components: { Account, Persons, Banks, Balance },
  data() {
    return {
      loading: false // hide the view when loading initially
    };
  },
  computed: {
    ...mapGetters(["stripeAccount"])
  },
  methods: {
    ...mapActions(["setStripeAccount"]),
    load() {
      const user = this.$auth.user();
      if (!user) return;
      this.loading = true;
      const data = { userId: user._id };
      this.axios
        .post("/stripe/accounts/retrieve", data)
        .then(response => {
          this.setStripeAccount(response.data);
          this.loading = false;
        })
        .catch(() => {
          this.loading = false;
        });
    }
  },
  mounted() {
    this.setStripeAccount(null);
    this.load();
  }
};
</script>