import _ from 'underscore'

function driverSchedule(schedule) {
  if (!schedule) return 'No Schedule'
  if (!schedule.status) return 'No Schedule'
  let slots = []
  let begin = null
  let end = null
  _.each(schedule.blocks, o => {
    if (o.status) {
      if (!begin) {
        begin = minutesToTime(o.mm)
      }
      end = minutesToTime(o.mm + 30)
    } else {
      if (begin && end) {
        slots.push(begin + '-' + end)
        begin = null
        end = null
      }
    }
  })
  if (begin && end) {
    slots.push(begin + '-' + end)
  }
  if (slots && slots.length) return slots.join(', ')
  else return 'No Schedule'
}

function minutesToTime(minutes) {
  if (isNaN(minutes)) return 'N/A'
  minutes = Math.round(minutes) // ensure integer
  var hh = Math.floor(minutes / 60)
  var ampm = 'AM'
  if (hh >= 12) {
    ampm = 'PM'
    hh -= 12
  }
  if (hh === 0) hh = 12
  hh = String(hh)
  var mm = String(minutes % 60)
  if (mm === '0') return hh + ' ' + ampm
  else {
    if (mm.length === 1) mm = '0' + mm
    return hh + ':' + mm + ' ' + ampm
  }
}

export default driverSchedule