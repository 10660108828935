<template lang="pug">
div
  v-row.my-3(justify="center")
    WeekSelector(@select="load")
  v-row(wrap, justify="center")
    v-col(cols="12", sm="6")
      WeeklyEarning(title="Selflane Summary", :result="selflaneResult")
      v-btn.text-capitalize(
        text,
        block,
        color="secondary",
        @click="showSLList()"
      ) View Details
    v-col(cols="12", sm="6")
      WeeklyEarning(
        title="Company Assigned Summary",
        :result="nonSelflaneResult"
      )
      v-btn.text-capitalize(
        text,
        block,
        color="secondary",
        @click="showNonSLList()"
      ) View Details
  Newsletter
  List
</template>

<script>
import moment from "moment";
import WeekSelector from "@/components/basic/WeekSelector";
import WeeklyEarning from "./WeeklyEarning";
import Newsletter from "./Newsletter";
import List from "./List";

import { EventBus } from "@/event-bus.js";

export default {
  components: { WeekSelector, WeeklyEarning, Newsletter, List },
  data() {
    return {
      range: {},
      selflaneResult: [],
      nonSelflaneResult: [],
    };
  },
  methods: {
    async load(range) {
      if (!range) return;
      this.range = range;
      const startDate = moment(range.begin).format("YYYY-MM-DD");
      const userId = this.$auth.user()._id;
      this.loadSLWeekly(userId, startDate);
      this.loadNonSLWeeklyEarning(userId, startDate);
    },
    async loadSLWeekly(userId, startDate) {
      const params = {
        userId: userId,
        startDate: startDate,
        isSelflane: true,
      };
      const res = await this.axios.post("/driver/stats/weeklyEarning", params);
      this.selflaneResult = res.data;
    },
    async loadNonSLWeeklyEarning(userId, startDate) {
      const params = {
        userId: userId,
        startDate: startDate,
        isSelflane: false,
      };
      const res = await this.axios.post("/driver/stats/weeklyEarning", params);
      this.nonSelflaneResult = res.data;
    },
    async showSLList() {
      const startDate = moment(this.range.begin).format("YYYY-MM-DD");
      const userId = this.$auth.user()._id;
      const params = {
        userId: userId,
        startDate: startDate,
        isSelflane: true,
      };
      const res = await this.axios.post("/driver/stats/weeklyOrders", params);
      EventBus.$emit("show-weekly-list", res.data);
    },
    async showNonSLList() {
      const startDate = moment(this.range.begin).format("YYYY-MM-DD");
      const userId = this.$auth.user()._id;
      const params = {
        userId: userId,
        startDate: startDate,
        isSelflane: false,
      };
      const res = await this.axios.post("/driver/stats/weeklyOrders", params);
      EventBus.$emit("show-weekly-list", res.data);
    },
  },
};
</script>
