<template lang="pug">
.d-flex.flex-row.align-center.mx-2.my-1
  v-icon(small) mdi-clock
  .px-3.body-2
    span.mr-2(v-if="delivOrder.pickupTime && delivOrder.pickupTime > 0") Picked {{ delivOrder.pickupTime | time }}
      span.red--text(
        v-if="!delivOrder.type && delivOrder.pickupTime > delivOrder.needed"
      ) {{ ((delivOrder.pickupTime - delivOrder.needed) / 60000) | number(0) }}' late
    span(v-if="delivOrder.deliverTime && delivOrder.deliverTime > 0") Delivered {{ delivOrder.deliverTime | time }}
      span.red--text(
        v-if="!delivOrder.type && delivOrder.deliverTime > delivOrder.needed"
      ) {{ ((delivOrder.deliverTime - delivOrder.needed) / 60000) | number(0) }}' late
</template>

<script>
export default {
  props: ["delivOrder"],
  computed: {
    mapLink() {
      if (this.delivOrder.customer && this.delivOrder.customer.address) {
        const address = this.delivOrder.customer.address;
        const url =
          "https://www.google.com/maps/search/?api=1&query=" +
          address.line1 +
          "," +
          address.city +
          "," +
          address.state;
        return encodeURI(url);
      }
      return undefined;
    },
  },
};
</script>
