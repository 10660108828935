<template lang="pug">
#app-links
  a(
    v-for="(item, index) in appLinks",
    :key="index",
    :href="item.url",
    target="_blank"
  )
    img(:src="item.icon")
</template>

<script>
export default {
  data() {
    return {
      appLinks: [
        {
          url: "https://itunes.apple.com/us/app/selflane/id1142403428?mt=8",
          icon:
            "https://firebasestorage.googleapis.com/v0/b/selflane-docs.appspot.com/o/icons%2Fappstore_icon%402x.png?alt=media&token=5332b552-31ac-4bc1-9c5b-800ca64e5d57",
        },
        // {
        //   url: "",
        //   icon:
        //     "https://firebasestorage.googleapis.com/v0/b/selflane-docs.appspot.com/o/icons%2Fgoogle_play_icon%402x.png?alt=media&token=e8544f36-a37b-45d3-8525-1fccab4db73a"
        // }
      ],
    };
  },
};
</script>

<style scoped>
#app-links a {
  margin-left: 20px;
  margin-right: 20px;
}

#app-links img {
  width: 135px;
  height: 40px;
}
</style>
