<template lang="pug">
div
  v-card.my-5(outlined)
    v-simple-table(dense)
      thead
        tr
          th On-time Score
            span.ml-1(v-if="overallScore") {{ overallScore | number(0) }} / 100
          th.text-right Count
      tbody
        tr(
          v-for="(item, score) in countByScore",
          :key="score",
          @click="show(item)"
        )
          td {{ score | scoreName }}
          td.text-right {{ item.length }}
  v-dialog(v-model="dialog", width="600", scrollable)
    v-card(v-if="selected_orders")
      v-simple-table(dense)
        thead
          tr
            th Restaurant
            th.text-right Needed
            th.text-right Delivered
        tbody
          tr(
            v-for="(item, index) in selected_orders",
            :key="index + 'selected'"
          )
            td {{ item.bizName }}
            td.text-right {{ item.needed | time }}
            td.text-right {{ item.deliverTime | time }}
</template>

<script>
import { mapGetters } from "vuex";
import _ from "underscore";

export default {
  data() {
    return {
      dialog: false,
      selected_orders: [],
    };
  },
  computed: {
    ...mapGetters(["delivOrders"]),
    countByScore() {
      return _.chain(this.delivOrders)
        .filter((o) => o.ontime_score != null && o.type == 0)
        .groupBy("ontime_score")
        .value();
    },
    overallScore() {
      if (!this.delivOrders || !this.delivOrders.length) return 0;
      const qualified = _.filter(this.delivOrders, (o) => {
        return o.ontime_score != null && o.type == 0;
      });
      if (!qualified.length) return 0;
      const total = _.reduce(
        qualified,
        (memo, item) => {
          return memo + item.ontime_score;
        },
        0
      );
      const count = qualified.length;
      return (total / count) * 20;
    },
  },
  filters: {
    scoreName(input) {
      const score = parseInt(input);
      switch (score) {
        case 5:
          return "Great";
        case 4:
          return "Good";
        case 3:
          return "Late";
        case 2:
          return "Very Late";
        case 1:
          return "Super Late";
        case 0:
          return "Disaster";
        default:
          return "Unknown";
      }
    },
  },
  methods: {
    show(list) {
      this.dialog = true;
      this.selected_orders = _.sortBy(list, "needed").reverse();
    },
  },
};
</script>
