<template lang="pug">
v-toolbar(flat, dense, color="grey lighten-3")
  .subtitle-2 {{ delivOrder.needed | datetime('ddd M/D h:mm A') }}
  v-spacer
  v-btn(icon, small, @click="show(delivOrder.order)", v-if="delivOrder.order")
    v-icon(color="secondary") mdi-information-outline
</template>

<script>
import { EventBus } from "@/event-bus.js";

export default {
  props: ["delivOrder"],
  methods: {
    show(order) {
      EventBus.$emit("show-order-detail", order);
    },
  },
};
</script>
