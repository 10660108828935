<template lang="pug">
v-btn(icon, @click="handleRemove()", :loading="loading")
  v-icon.red--text(small) mdi-delete
</template>

<script>
import { mapGetters } from "vuex";
import { EventBus } from "@/event-bus.js";

export default {
  name: "DeleteBank",
  props: ["person"],
  data() {
    return {
      loading: false,
    };
  },
  computed: {
    ...mapGetters(["stripeAccount"]),
  },
  methods: {
    handleRemove() {
      confirm("Do you want to delete this owner?") && this.remove();
    },
    remove() {
      if (!this.stripeAccount) return;
      if (!this.person) return;
      this.loading = true;
      const options = {
        accountId: this.stripeAccount.id,
        personId: this.person.id,
      };
      this.axios.post("/stripe/persons/delete", options).then(() => {
        EventBus.$emit("reload-stripe-persons");
        this.loading = false;
      });
    },
  },
};
</script>
