<template lang="pug">
v-app-bar(app, dark, flat, color="primary")
  v-btn(icon, @click="toHome()")
    v-icon $selflane
  .title.ml-2 Selflane Driver
  v-spacer
  v-btn(text, @click="logout", v-if="$auth.check()") Logout
</template>

<script>
export default {
  methods: {
    toHome() {
      const currentPath = this.$router.history.current.path;
      if (currentPath != "/") this.$router.push("/");
    },
    logout() {
      const clientId = process.env.VUE_APP_GOOGLE_CLIENT;
      // eslint-disable-next-line
      gapi.load("auth2", initAuth2);
      const finish = this.$auth.logout();
      function initAuth2() {
        // eslint-disable-next-line
        gapi.auth2.init({ client_id: clientId }).then(() => {
          // eslint-disable-next-line
          gapi.auth2.getAuthInstance().signOut();
          finish;
        });
      }
    },
  },
};
</script>
