<template lang="pug">
v-dialog(v-model="dialog", width="500")
  v-card
    v-toolbar(dense, flat)
      .subtitle-2 Week Details
    v-data-table(
      :items="items",
      :headers="headers",
      dense,
      mobile-breakpoint="0"
    )
</template>

<script>
import _ from "underscore";
import { EventBus } from "@/event-bus.js";

export default {
  name: "DelivOrderWeeklyList",
  data() {
    return {
      dialog: false,
      list: [],
      headers: [
        { text: "Date", value: "date" },
        { text: "Biz", value: "bizName" },
        { text: "Amount", value: "amount" },
      ],
    };
  },
  computed: {
    items() {
      const date = this.$options.filters.date;
      const currency = this.$options.filters.currency;
      return _.chain(this.list)
        .sortBy("needed")
        .map((o) => {
          return {
            date: date(o.needed),
            bizName: o.bizName,
            amount: currency(o.fee + o.tip + o.reward),
          };
        })
        .value();
    },
  },
  methods: {
    open(list) {
      this.list = list;
      this.dialog = true;
    },
  },
  mounted() {
    EventBus.$on("show-weekly-list", this.open);
  },
  destroyed() {
    EventBus.$off("show-weekly-list");
  },
};
</script>
