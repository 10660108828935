const state = {
  driver: null,
};

const getters = {
  driver: state => state.driver,
};

const actions = {
  setDriver: ({
    commit
  }, data) => {
    commit("setDriver", data);
  }
};

const mutations = {
  setDriver(state, data) {
    state.driver = data;
  },
};

export default {
  state,
  getters,
  actions,
  mutations
};