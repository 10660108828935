<template lang="pug">
v-dialog(v-model="dialog", width="500")
  template(v-slot:activator="{ on }")
    v-btn.text-capitalize(block, color="error", v-on="on") Reject Order
  v-card
    v-toolbar(flat)
      .subtitle-2 Reject an Order
    v-card-text
      div I can't take this order and need the manager to assign it to another driver.
      div Please select or enter a reason below.
    v-list
      v-list-item(
        v-for="(item, index) in reasonItems",
        :key="index",
        @click="reason = item"
      )
        v-list-item-content {{ item }}
    v-card-text
      v-form(@submit.prevent="send")
        v-text-field(v-model="reason", label="Reason")
        v-btn(
          color="secondary",
          type="submit",
          :disabled="!reason",
          :loading="loading"
        ) Submit
</template>

<script>
import { mapActions } from "vuex";
export default {
  props: ["delivOrder"],
  data() {
    return {
      dialog: false,
      reason: "",
      loading: false,
      reasonItems: [
        "I am too far from the restaurant",
        "I am working on another order at the time",
        "I am not working today",
      ],
    };
  },
  methods: {
    ...mapActions(["removeDelivOrder"]),
    open() {
      this.reason = "";
    },
    async send() {
      if (!this.delivOrder) return;
      if (!this.reason || !this.reason.length) return;
      this.loading = true;
      const params = {
        delivOrderId: this.delivOrder._id,
        driverId: this.delivOrder.driver.id,
        reason: this.reason,
      };
      try {
        const res = await this.axios.post("/delivOrders/reject", params);
        this.removeDelivOrder(res.data);
        this.dialog = false;
      } catch (err) {
        if (err && err.response) alert(err.response.data);
      }
      this.loading = false;
    },
  },
};
</script>