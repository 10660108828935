<template lang="pug">
v-card(flat, v-if="!hasUnderReview")
  v-form(@submit.prevent="save")
    v-card-text
      v-select(
        label="File Type",
        v-model="file_type",
        :items="file_type_items"
      )
      v-file-input(
        show-size,
        accept="image/*,.pdf",
        label="Upload File",
        hint="Accept Image or PDF File. Limited to 5MB",
        persistent-hint,
        v-model="file",
        :loading=loading
      )
    v-card-actions
      v-btn(type="submit", block, color="secondary", :loading="loading") Submit
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import _ from "underscore";

export default {
  data() {
    return {
      file: null,
      file_type: "Server Permit",
      file_type_items: ["Server Permit"],
      loading: false,
    };
  },
  computed: {
    ...mapGetters(["driverDocuments"]),
    hasUnderReview() {
      const found = _.find(this.driverDocuments, (o) => o.status == 0);
      return found != undefined;
    },
  },
  methods: {
    ...mapActions(["addDriverDocument"]),
    async save() {
      const user = this.$auth.user();
      if (!user) return;
      if (!this.file) {
        alert("A valid file is required");
        return;
      }
      if (this.file.size > 5242880) {
        alert("Please provide a file with size less than 5MB");
        return;
      }
      this.loading = true;
      const formData = new FormData();
      formData.append("user", user._id);
      formData.append("file_type", this.file_type);
      formData.append("file", this.file);
      try {
        const res = await this.axios.post("/driver/documents/create", formData);
        this.addDriverDocument(res.data);
      } catch (err) {
        if (err.response) alert(err.response.data);
        console.log(err);
      }
      this.loading = false;
    },
  },
};
</script>

