<template lang="pug">
v-dialog(v-model="dialog", width="600", scrollable)
  v-card(v-if="payout")
    v-toolbar(flat, dense)
      .subtitle-2 Payout Details
    v-card-text
      v-simple-table(dense)
        tbody
          tr
            td #Trip
            td.text-right {{ payout.count }}
          tr
            td Base Fee
            td.text-right {{ payout.fee | currency }}
          tr
            td Tip
            td.text-right {{ payout.tip | currency }}
          tr
            td Other
            td.text-right {{ payout.reward | currency }}
          tr
            td Deposit Fee (once a month)
            td.text-right {{ -payout.deduct | currency }}
          tr
            th Total
            th.text-right {{ payout.amount | currency }}
      .subtitle-2 List of Trips
      v-data-table(
        :items="delivOrders",
        :headers="headers",
        sort-by="needed",
        dense
      )
        template(v-slot:item.needed="{ item }")
          span {{ item.needed | datetime('ddd M/D h:mm A') }}
        template(v-slot:item.total="{ item }")
          span {{ (item.fee + item.tip + item.reward) | currency }}
      .subtitle-2 Other activities
      v-data-table(
        :items="rewardPenalties",
        :headers="rewardPenaltiesHeaders",
        sort-by="created",
        dense
      )
        template(v-slot:item.created="{ item }")
          span {{ item.created | date }}
        template(v-slot:item.amount="{ item }")
          span {{ item.amount | currency }}
</template>

<script>
import { EventBus } from "@/event-bus.js";
import { mapActions, mapGetters } from "vuex";

export default {
  data() {
    return {
      dialog: false,
      payout: null,
      rewardPenalties: [],
      headers: [
        { text: "Date", value: "needed" },
        { text: "Biz", value: "bizName" },
        { text: "Total", value: "total", align: "end" },
      ],
      rewardPenaltiesHeaders: [
        { text: "Date", value: "created" },
        { text: "Type", value: "type" },
        { text: "Reason", value: "reason" },
        { text: "Total", value: "amount", align: "end" },
      ],
    };
  },
  computed: {
    ...mapGetters(["delivOrders"]),
  },
  methods: {
    ...mapActions(["setDelivOrders"]),
    async show(payout) {
      this.payout = payout;
      if (!payout) return;
      this.loadOrders(payout);
      this.loadRewardPenalties(payout);
      this.dialog = true;
    },
    async loadOrders(payout) {
      if (!payout) return;
      const options = { criteria: { driverPayout: this.payout._id } };
      const res = await this.axios.post("/delivOrders/list", options);
      this.setDelivOrders(res.data);
    },
    async loadRewardPenalties(payout) {
      if (!payout) return;
      const params = { criteria: { driverPayout: this.payout._id } };
      const res = await this.axios.post("/driver/rewardPenalties/list", params);
      this.rewardPenalties = res.data;
    },
  },
  mounted() {
    EventBus.$on("show-driver-payout", this.show);
  },
  destroyed() {
    EventBus.$off("show-driver-payout");
  },
};
</script>
