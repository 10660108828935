import _ from "underscore";

const state = {
  geoServiceCountries: []
};

const getters = {
  geoServiceCountries: state => state.geoServiceCountries
};

const actions = {
  setGeoServiceCountries: ({
    commit
  }, data) => {
    commit("setGeoServiceCountries", data);
  },
  updateGeoServiceCountry: ({
    commit
  }, data) => {
    commit("updateGeoServiceCountry", data);
  },
  addGeoServiceCountry: ({
    commit
  }, data) => {
    commit("addGeoServiceCountry", data);
  }
};

const mutations = {
  setGeoServiceCountries(state, data) {
    state.geoServiceCountries = data;
  },
  updateGeoServiceCountry(state, data) {
    state.geoServiceCountries = _.map(state.geoServiceCountries, o => {
      if (o._id === data._id) return data;
      return o;
    });
  },
  addGeoServiceCountry(state, data) {
    state.geoServiceCountries.push(data);
  }
};

export default {
  state,
  getters,
  actions,
  mutations
};