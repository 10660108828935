<template lang="pug">
div
  Guest
  v-container(v-if="$auth.check()")
    Greeting
    Panels(v-if="driver")
    NewDriver(v-if="!loading && !driver")
</template>

<script>
import Greeting from "./Greeting/index";
import Guest from "./Guest";
import Panels from "./Panels";
import NewDriver from "./NewDriver";
import { mapActions, mapGetters } from "vuex";
import { EventBus } from "@/event-bus";

export default {
  components: { Greeting, Guest, Panels, NewDriver },
  data() {
    return {
      loading: false,
    };
  },
  computed: {
    ...mapGetters(["driver"]),
  },
  methods: {
    ...mapActions(["setDriver", "setGeoServiceZones"]),
    async loadDriver() {
      const user = this.$auth.user();
      if (!user || !user._id) {
        return;
      }
      this.loading = true;
      const options = {
        criteria: { user: user._id },
      };
      try {
        const res = await this.axios.post("/driver/accounts/retrieve", options);
        this.setDriver(res.data);
      } catch (err) {
        this.setDriver();
      }
      this.loading = false;
    },
    async loadZones() {
      const params = {
        select: "country zoneName zoneDetail",
      };
      const res = await this.axios.post("/geoService/zones/list", params);
      this.setGeoServiceZones(res.data);
    },
  },
  mounted() {
    this.loadZones();
    this.loadDriver();
    EventBus.$on("login", this.loadDriver);
  },
  destroyed() {
    EventBus.$off("login");
  },
};
</script>
