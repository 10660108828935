<template lang="pug">
v-footer(dark, padless)
  v-card.navy(flat, tile, width="100%")
    v-card-text
      Links
    v-divider
    .d-flex.flex-row.align-center.justify-center.flex-wrap
      .caption Copyright &copy; {{ new Date().getFullYear() }} Selflane.com All rights reserved.
      router-link.link-item.mx-3(to="/privacy") Privacy Policy
      .vertical-splitter
      router-link.link-item.mx-3(to="/terms") Terms of Use
</template>

<script>
import AppLinks from "./AppLinks";
import Links from "./Links";

export default {
  name: "Footer",
  components: { AppLinks, Links },
};
</script>

<style scoped>
a {
  text-decoration: none;
}
.link-item {
  color: rgba(246, 247, 252, 0.7);
  font-size: 12px;
}
.vertical-splitter {
  width: 1.1px;
  height: 14px;
  background-color: rgba(246, 247, 252, 0.7);
}
</style>
