<template lang="pug">
BarChart(:chartData='chartData' :options='options' :height='height' v-if='input')
</template>

<script>
import BarChart from "./BarChart";

export default {
  name: "StandardBarChart",
  props: ["input"],
  components: { BarChart },
  data() {
    return {
      height: 250,
      chartData: {},
      options: {
        title: {
          display: true,
          text: "",
        },
        legend: {
          display: false,
        },
        scales: {
          xAxes: [
            {
              gridLines: {
                display: false,
              },
            },
          ],
          yAxes: [
            {
              gridLines: {
                display: false,
              },
              ticks: {
                min: 0,
                precision: 0,
              },
            },
          ],
        },
      },
    };
  },
  watch: {
    input(val) {
      if (val) this.setData();
    },
  },
  methods: {
    setData() {
      if (!this.input) return;
      this.options.title.text = this.input.title;
      if (this.input.isCurrency) {
        this.options.scales.yAxes = [
          {
            gridLines: {
              display: false,
            },
            ticks: {
              min: 0,
              precision: 0,
              callback: (value) => {
                return "$" + value;
              },
            },
          },
        ];
      }
      this.chartData = {
        labels: this.input.labels,
        datasets: [
          {
            backgroundColor: "#7682d6",
            data: this.input.values,
          },
        ],
      };
    },
  },
};
</script>
