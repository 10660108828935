<template lang="pug">
v-container
  Note
  Table(:items='taxfiles')
</template>

<script>
import Note from "./Note";
import Table from "./Table";

export default {
  components: { Note, Table },
  data() {
    return {
      taxfiles: []
    };
  },
  methods: {
    load() {
      const userId = this.$auth.user()._id;
      const options = {
        criteria: {
          user: userId
        }
      };
      this.axios.post("/tax/taxfile/list", options).then(response => {
        this.taxfiles = response.data;
      });
    }
  },
  mounted() {
    this.load();
  }
};
</script>
