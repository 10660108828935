<template lang="pug">
div
  .d-flex.flex-row.align-center.mb-3
    .sl-title Driver Payouts
    v-spacer
    YearSelector(@select="select")
  List.mb-5
  .caption We deduct $3 from the balance for the monthly fee and deposit fee that is charged by Stripe. There is no transaction fee applied to you.
</template>

<script>
import List from "./List/index";
import YearSelector from "@/components/basic/YearSelector";
import { mapActions } from "vuex";
import moment from "moment";

export default {
  components: { YearSelector, List },
  data() {
    return { year: null };
  },
  methods: {
    ...mapActions(["setDriverPayouts"]),
    async select(year) {
      const user = this.$auth.user();
      if (!user || !year) return;
      const begin = moment().year(year).startOf("year").unix() * 1000;
      const end = moment().year(year).endOf("year").unix() * 1000;
      const params = {
        criteria: { user: user._id, created: { $gte: begin, $lt: end } },
      };
      const res = await this.axios.post("/driver/payouts/list", params);
      this.setDriverPayouts(res.data);
    },
  },
};
</script>
