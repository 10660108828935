var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-dialog',{attrs:{"width":"600","scrollable":""},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[(_vm.payout)?_c('v-card',[_c('v-toolbar',{attrs:{"flat":"","dense":""}},[_c('div',{staticClass:"subtitle-2"},[_vm._v("Payout Details")])]),_c('v-card-text',[_c('v-simple-table',{attrs:{"dense":""}},[_c('tbody',[_c('tr',[_c('td',[_vm._v("#Trip")]),_c('td',{staticClass:"text-right"},[_vm._v(_vm._s(_vm.payout.count))])]),_c('tr',[_c('td',[_vm._v("Base Fee")]),_c('td',{staticClass:"text-right"},[_vm._v(_vm._s(_vm._f("currency")(_vm.payout.fee)))])]),_c('tr',[_c('td',[_vm._v("Tip")]),_c('td',{staticClass:"text-right"},[_vm._v(_vm._s(_vm._f("currency")(_vm.payout.tip)))])]),_c('tr',[_c('td',[_vm._v("Other")]),_c('td',{staticClass:"text-right"},[_vm._v(_vm._s(_vm._f("currency")(_vm.payout.reward)))])]),_c('tr',[_c('td',[_vm._v("Deposit Fee (once a month)")]),_c('td',{staticClass:"text-right"},[_vm._v(_vm._s(_vm._f("currency")(-_vm.payout.deduct)))])]),_c('tr',[_c('th',[_vm._v("Total")]),_c('th',{staticClass:"text-right"},[_vm._v(_vm._s(_vm._f("currency")(_vm.payout.amount)))])])])]),_c('div',{staticClass:"subtitle-2"},[_vm._v("List of Trips")]),_c('v-data-table',{attrs:{"items":_vm.delivOrders,"headers":_vm.headers,"sort-by":"needed","dense":""},scopedSlots:_vm._u([{key:"item.needed",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm._f("datetime")(item.needed,'ddd M/D h:mm A')))])]}},{key:"item.total",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm._f("currency")((item.fee + item.tip + item.reward))))])]}}],null,false,4093337229)}),_c('div',{staticClass:"subtitle-2"},[_vm._v("Other activities")]),_c('v-data-table',{attrs:{"items":_vm.rewardPenalties,"headers":_vm.rewardPenaltiesHeaders,"sort-by":"created","dense":""},scopedSlots:_vm._u([{key:"item.created",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm._f("date")(item.created)))])]}},{key:"item.amount",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm._f("currency")(item.amount)))])]}}],null,false,3116298842)})],1)],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }