<template lang="pug">
v-simple-table
  thead
    tr
      th Year
      th Type
      th.text-right Action
  tbody
    tr(v-for="(item, index) in items", :key="index")
      td {{ item.year }}
      td {{ item.filetype }}
      td.text-right
        v-btn(small, rounded, depressed, @click="download(item)") Download
</template>

<script>
import FileSaver from "file-saver";

export default {
  props: ["items"],
  methods: {
    download(item) {
      if (!item) return;
      const options = {
        fileid: item._id,
      };
      const name = item.filetype + "-" + item.year + ".pdf";
      this.axios
        .post("tax/taxfile/open", options, { responseType: "blob" })
        .then((file) => {
          FileSaver.saveAs(file.data, name);
        })
        .catch((err) => {
          if (err && err.response) alert(err.response.data);
        });
    },
  },
};
</script>
