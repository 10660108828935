<template lang="pug">
v-card.my-3
  v-card-text
    .d-flex.flex-row.align-center.justify-space-between
      .caption.sl-secondary-text Company Owner
      CreatePerson
    v-divider.mt-2
  List
</template>

<script>
import List from "./List";
import CreatePerson from "./CreatePerson";

export default {
  components: { List, CreatePerson }
};
</script>
