<template lang="pug">
table.mx-2(v-if="delivOrder")
  tr(v-if="delivOrder.orderNumber")
    th Order
    td \#{{ delivOrder.orderNumber }}
    th
    td
  tr
    th Fee
    td
      span(v-if="delivOrder.fee > 0") {{ delivOrder.fee | currency }}
    th Tip
    td {{ delivOrder.tip | currency }}
</template>

<script>
export default {
  props: ["delivOrder"],
};
</script>
