import _ from "underscore";

const state = {
  geoServiceZones: []
};

const getters = {
  geoServiceZones: state => state.geoServiceZones
};

const actions = {
  setGeoServiceZones: ({
    commit
  }, data) => {
    commit("setGeoServiceZones", data);
  },
  updateGeoServiceZone: ({
    commit
  }, data) => {
    commit("updateGeoServiceZone", data);
  },
  addGeoServiceZone: ({
    commit
  }, data) => {
    commit("addGeoServiceZone", data);
  }
};

const mutations = {
  setGeoServiceZones(state, data) {
    state.geoServiceZones = data;
  },
  updateGeoServiceZone(state, data) {
    state.geoServiceZones = _.map(state.geoServiceZones, o => {
      if (o._id === data._id) return data;
      return o;
    });
  },
  addGeoServiceZone(state, data) {
    state.geoServiceZones.push(data);
  }
};

export default {
  state,
  getters,
  actions,
  mutations
};