<template lang="pug">
v-container
  Instruction
  Stripe
</template>

<script>
import Instruction from "./Instruction";
import Stripe from "./Stripe/index";

export default {
  components: { Instruction, Stripe },
};
</script>
