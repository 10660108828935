<template lang="pug">
v-row.my-5(v-if="driver", wrap)
  v-col(cols="12", sm="6")
    v-card(outlined, v-if="has_delivery", color="secondary", dark)
      v-list-item(to="/orders")
        v-list-item-icon
          v-icon mdi-clock-outline
        v-list-item-content Orders
        v-list-item-icon
          v-icon mdi-chevron-right
  v-col(cols="12", sm="6")
    v-card(outlined, color="secondary", dark)
      v-list-item(to="/account")
        v-list-item-icon
          v-icon mdi-bank
        v-list-item-content Account
        v-list-item-icon
          v-icon mdi-chevron-right
</template>

<script>
import { mapGetters } from "vuex";
export default {
  computed: {
    ...mapGetters(["driver"]),
    has_delivery() {
      return this.driver && this.driver.deliv;
    },
  },
};
</script>
