<template lang="pug">
v-card.my-3
  v-card-text
    .d-flex.flex-row.align-center.justify-space-between
      .caption.sl-secondary-text Payout Account
    v-divider.mt-2
    Details
    CreateAccount
</template>

<script>
import Details from "./Details";
import CreateAccount from "./CreateAccount/index";

export default {
  components: { Details, CreateAccount }
};
</script>
