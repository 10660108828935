import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from 'vuex-persistedstate'
import orders from './modules/orders'
import delivOrders from './modules/delivOrders'
import deliv from './modules/deliv'
import driverIds from './modules/driverIds'

import driver from './modules/Driver/driver'
import driverPayouts from './modules/Driver/payouts'
import driverSchedules from './modules/Driver/schedules'
import driverDocuments from './modules/Driver/documents'

import stripeAccount from './modules/Stripe/account'
import stripeBanks from './modules/Stripe/banks'
import stripePersons from './modules/Stripe/persons'

import bizAccounts from './modules/Business/accounts'
import chains from './modules/Business/chains'

import geoServiceCountries from './modules/GeoService/countries'
import geoServiceZones from './modules/GeoService/zones'

Vue.use(Vuex)

const store = new Vuex.Store({
  plugins: [createPersistedState()],
  modules: {
    orders,
    delivOrders,
    deliv,
    driverIds,
    driver,
    driverPayouts,
    driverSchedules,
    driverDocuments,
    stripeAccount,
    stripeBanks,
    stripePersons,
    bizAccounts, // business bizs
    chains,
    geoServiceCountries,
    geoServiceZones,
  }
})
export default store