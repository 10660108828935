<template lang="pug">
v-card
  v-list
    v-list-item
      v-list-item-content
        v-list-item-title Driver Newsletter Subscription
      v-list-item-action
        v-switch(
          v-model="driver.subscribe",
          color="secondary",
          :loading="loading",
          @click="toggle()"
        )
</template>

<script>
import { mapActions, mapGetters } from "vuex";
export default {
  computed: {
    ...mapGetters(["driver"]),
  },
  data() {
    return { loading: false };
  },
  methods: {
    ...mapActions(["setDriver"]),
    async toggle() {
      if (!this.driver) return;
      const params = {
        criteria: { _id: this.driver._id },
        action: { $set: { subscribe: this.driver.subscribe } },
      };
      this.loading = true;
      const res = await this.axios.post("/driver/accounts/update", params);
      this.setDriver(res.data);
      this.loading = false;
    },
  },
};
</script>