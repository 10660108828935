// Page view if user hasn't logged in
<template lang="pug">
v-parallax(:src="bkg_file", v-if="!$auth.check()", height="800")
  v-row(align="center", justify="center")
    v-card(
      max-width="500",
      width="90%",
      color="rgba(255, 255, 255, 0.8)",
      shaped
    )
      .text-center.py-8
        .title Selflane for Delivery Drivers
        v-btn.my-10.text-capitalize(
          to="/login",
          x-large,
          color="secondary",
          depressed,
          rounded
        ) Driver Login
      v-card-text.text-center
        .subtitle-2 If you're looking for other services, please visit
        v-btn(href="https://selflane.com", text) selflane.com
</template>

<script>
export default {
  data() {
    return {
      bkg_file:
        "https://firebasestorage.googleapis.com/v0/b/selflane-docs.appspot.com/o/background%2Fcity_busy.jpg?alt=media&token=489db921-5055-4b03-b618-3850d83dbe59",
    };
  },
};
</script>