<template lang="pug">
v-dialog(v-model="dialog", max-width="500")
  v-card(v-if="order")
    v-card-text.text-center.px-0.pt-2
      v-chip(small) {{ order.seller.industry | bizIndustry }}
      .subtitle-2 {{ order.seller.name }}
      .subtitle-2 Ticket: \#{{ order.orderNumber }}
      .d-flex.flex-row.justify-center.align-center
        v-icon.mr-2 mdi-account-circle
        .subtitle-2 {{ order.customer.name }} {{ order.customer.phone | phone }}
      v-divider.my-2
      .subtitle-2 {{ order.needed | datetime }}
      span Server: {{ order.orderer.name }}
    v-simple-table(dense)
      tbody
        tr(v-for="(dish, index) in order.dishes", :key="index + 'dish'")
          td.text-truncate
            div {{ dish.name }}
            .green--text {{ dish.modifiers | modifiers }}
          td.text-right {{ dish.unitPrice | currency }} &#215; {{ dish.quantity }}
        tr
          th Total
          th.text-right {{ order.payment.total | currency }}
</template>

<script>
import { EventBus } from "@/event-bus.js";

export default {
  name: "OrderDetail",
  data() {
    return {
      order: null,
      dialog: false,
    };
  },
  methods: {
    async load(orderId) {
      if (!orderId) return;
      this.dialog = true;
      const params = { criteria: { _id: orderId } };
      const res = await this.axios.post("/orders/retrieve", params);
      this.order = res.data;
    },
  },
  mounted() {
    EventBus.$on("show-order-detail", this.load);
  },
  destroyed() {
    EventBus.$off("show-order-detail");
  },
};
</script>
