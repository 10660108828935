<template lang="pug">
.px-3
  List
  Detail
  DailySchedule
  OntimeScore
  Instruction
  RefreshGeo
  .text-center
    v-btn.text-capitalize(
      to="/account/schedule",
      rounded,
      outlined,
      small,
      color="secondary"
    ) Manage Schedule
</template>

<script>
import List from "./List";
import Detail from "./Detail";
import Instruction from "./Instruction";
import DailySchedule from "./DailySchedule/index";
import OntimeScore from "./OntimeScore";
import RefreshGeo from "./RefreshGeo/index";

export default {
  components: {
    List,
    Detail,
    DailySchedule,
    Instruction,
    OntimeScore,
    RefreshGeo,
  },
  methods: {
    async loadDriver() {
      const user = this.$auth.user();
      if (!user || !user._id) {
        return;
      }
      this.loading = true;
      const params = { criteria: { user: user._id } };
      try {
        const res = await this.axios.post("/driver/accounts/retrieve", params);
        this.setDriver(res.data);
      } catch (err) {
        this.setDriver();
      }
      this.loading = false;
    },
  },
};
</script>
