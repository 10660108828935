<template lang="pug">
.d-flex.flex-row.align-center.mx-2.my-1
  v-icon(small) mdi-account
  .px-3.body-2(v-if="delivOrder.customer")
    .subtitle-2.mb-1 {{ delivOrder.customer.name }}
    .mb-1 {{ delivOrder.customer.phone | phone }}
    div {{ delivOrder.customer.address | address(true) }}
    .green--text.font-weight-medium(v-if="delivOrder.customer.address") {{ delivOrder.customer.address.note }}
    .red--text.font-weight-medium {{ delivOrder.note }}
  v-spacer
  v-btn.text-capitalize(
    small,
    outlined,
    rounded,
    color="secondary",
    :href="mapLink",
    target="_blank",
    v-if="mapLink"
  ) Map
</template>

<script>
export default {
  props: ["delivOrder"],
  computed: {
    mapLink() {
      if (this.delivOrder.customer && this.delivOrder.customer.address) {
        const address = this.delivOrder.customer.address;
        const url =
          "https://www.google.com/maps/search/?api=1&query=" +
          address.line1 +
          "," +
          address.city +
          "," +
          address.state;
        return encodeURI(url);
      }
      return undefined;
    },
  },
};
</script>
