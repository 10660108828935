import _ from 'underscore'

const state = {
  chains: []
}

const getters = {
  chains: state => state.chains
}

const actions = {
  setChains: ({
    commit
  }, chains) => {
    commit('setChains', chains)
  },
  addChain: ({
    commit
  }, chain) => {
    commit('addChain', chain)
  },
  updateChain: ({
    commit
  }, chain) => {
    commit('updateChain', chain)
  },
  removeChain: ({
    commit
  }, chain) => {
    commit('removeChain', chain)
  }
}

const mutations = {
  setChains(state, chains) {
    state.chains = chains
  },
  addChain(state, chain) {
    state.chains = state.chains.concat([chain])
  },
  updateChain(state, chain) {
    state.chains = _.map(state.chains, o => {
      if (o._id === chain._id) return chain
      return o
    })
  },
  removeChain(state, chain) {
    state.chains = _.reject(state.chains, o => {
      return o._id === chain._id
    })
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}