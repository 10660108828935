<template lang="pug">
div
  v-list.py-0(dense, two-line)
    v-list-item(v-for="(item, index) in stripeBanks", :key="index")
      v-list-item-content
        v-list-item-title ... {{ item.last4 }}
          v-chip.ml-3(
            v-if="item.default_for_currency",
            small,
            dark,
            color="accent"
          ) default
        v-list-item-subtitle {{ item.bank_name }}
      v-list-item-action
        v-layout(v-if="!item.default_for_currency")
          SetDefaultBank(:bankAccount="item")
          DeleteBank(:bankAccount="item")
  .ma-3.text-center.subtitle-2(v-if="!stripeBanks || !stripeBanks.length") Please Add a Bank Account
  .caption.sl-secondary-text.mx-3 Need to deposit to a different bank account? Add a new account then you would be able to delete the other one.
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import { EventBus } from "@/event-bus.js";
import SetDefaultBank from "./SetDefaultBank";
import DeleteBank from "./DeleteBank";

export default {
  components: { SetDefaultBank, DeleteBank },
  computed: {
    ...mapGetters(["stripeAccount", "stripeBanks"]),
  },
  methods: {
    ...mapActions(["setStripeBanks"]),
    load() {
      if (!this.stripeAccount) return;
      const options = { accountId: this.stripeAccount.id };
      this.axios.post("/stripe/bankaccounts/list", options).then((response) => {
        this.setStripeBanks(response.data);
      });
    },
  },
  mounted() {
    this.setStripeBanks([]);
    this.load();
    EventBus.$on("reload-bank-account", this.load);
  },
  destroyed() {
    EventBus.$off("reload-bank-account");
  },
};
</script>
