<template lang="pug">
v-row(justify='center' v-if="$auth.check()")
  v-card(max-width="450" width="95%")
    v-toolbar(flat dense)
      .subtitle-2 Sign Up as a Driver
    v-card-text
      v-form(@submit.prevent='submit')
        v-text-field(v-model='name' label='Name' :error-messages='nameErrors' @blur='$v.name.$touch()')
        v-text-field(v-model='phone' v-mask='phoneMask' label='Phone' :error-messages='phoneErrors' @blur='$v.phone.$touch()')
        v-select(v-model='country' :items='country_items' label='Country')
        v-select(v-model='zones' :items='countryZones' label='Working Zones' multiple)
        v-btn(block color="secondary" type="submit" :disabled='$v.$invalid' :loading='loading') Sign me Up
</template>

<script>
import { mask } from "vue-the-mask";
import { validationMixin } from "vuelidate";
import { required } from "vuelidate/lib/validators";
import { mapActions, mapGetters } from "vuex";
import _ from "underscore";

export default {
  name: "CreateDriver",
  directives: { mask },
  mixins: [validationMixin],
  validations: {
    name: { required },
    phone: { required },
  },
  data() {
    return {
      loading: false,
      name: "",
      phone: "",
      phoneMask: "(###) ###-####",
      country: "US",
      zones: [],
      country_items: [
        { value: "US", text: "United States" },
        { value: "CA", text: "Canada" },
      ],
    };
  },
  computed: {
    ...mapGetters(["geoServiceZones"]),
    countryZones() {
      return _.chain(this.geoServiceZones)
        .filter(o => o.country == this.country)
        .sortBy("zoneName")
        .map(o => {
          return {
            value: o.zoneName,
            text: o.zoneDetail || o.zoneName,
          };
        })
        .value();
    },
    nameErrors() {
      const errors = [];
      if (!this.$v.name.$dirty) return errors;
      !this.$v.name.required && errors.push("Name is required");
      return errors;
    },
    phoneErrors() {
      const errors = [];
      if (!this.$v.phone.$dirty) return errors;
      !this.$v.phone.required && errors.push("Phone is required");
      return errors;
    },
  },
  methods: {
    ...mapActions(["setDriver"]),
    async submit() {
      if (!this.$auth.user()) return;
      if (!this.zones || !this.zones.length) {
        alert("Please select a working zone");
        return;
      }
      const userId = this.$auth.user()._id;
      const options = {
        userId: userId,
        name: this.name.trim(),
        phone: this.phone.replace(/[^\d]/g, ""),
        zones: this.zones,
        country: this.country,
      };
      this.loading = true;
      try {
        const res = await this.axios.post("/driver/accounts/signup", options);
        this.setDriver(res.data);
      } catch (err) {
        alert(err.response.data);
      }
      this.loading = false;
    },
  },
};
</script>
