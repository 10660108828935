<template lang="pug">
v-simple-table
  thead
    tr
      th Created
      th # Orders
      th Total
      th
  tbody
    tr(v-for="(item, index) in driverPayouts", :key="index")
      td {{ item.created | datetime('M/D/YY') }}
      td {{ item.count }}
      td {{ item.amount | currency }}
      td.text-right
        v-btn(rounded, depressed, small, @click="show(item)") Details
  DelivOrders
</template>

<script>
import { mapGetters } from "vuex";
import DelivOrders from "./DelivOrders";
import { EventBus } from "@/event-bus.js";

export default {
  components: { DelivOrders },
  computed: {
    ...mapGetters(["driverPayouts"]),
  },
  methods: {
    show(item) {
      EventBus.$emit("show-driver-payout", item);
    },
  },
};
</script>
