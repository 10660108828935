<template lang="pug">
div
  Intro
  List
  Form.my-5
  ViewDocument
</template>

<script>
import Intro from "./Intro";
import Form from "./Form";
import List from "./List";
import ViewDocument from "./ViewDocument";

export default {
  components: { Intro, Form, List, ViewDocument },
};
</script>