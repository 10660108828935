<template lang="pug">
div
  div
    .special-item(v-if="hasAlcohol") Alcohol
    .special-item(v-if="hasBeverage") Beverage
  .indigo--text.text-center(
    v-if="delivOrder.bizmsg && delivOrder.bizmsg.length > 0"
  )
    div Restaurant note
    div {{ delivOrder.bizmsg }}
  .d-flex.flex-row.align-center.mx-2.my-1
    v-icon(small) mdi-store
    .px-3.body-2
      .subtitle-2.mb-1 {{ delivOrder.bizName }}
      .mb-1(v-if="biz && biz.phones && biz.phones.length") {{ biz.phones[0].number | phone }}
      div(v-if="biz") {{ biz.address | address(true) }}
    v-spacer
    v-btn.text-capitalize(
      small,
      outlined,
      rounded,
      color="secondary",
      :href="mapLink",
      target="_blank",
      v-if="mapLink"
    ) Map
</template>

<script>
import { mapGetters } from "vuex";
import _ from "underscore";

export default {
  props: ["delivOrder"],
  computed: {
    ...mapGetters(["bizAccounts"]),
    biz() {
      return _.find(this.bizAccounts, (o) => o._id == this.delivOrder.biz);
    },
    mapLink() {
      if (this.biz && this.biz.address) {
        const address = this.biz.address;
        const url =
          "https://www.google.com/maps/search/?api=1&query=" +
          address.line1 +
          "," +
          address.city +
          "," +
          address.state;
        return encodeURI(url);
      }
      return undefined;
    },
    hasAlcohol() {
      const found = _.find(this.delivOrder.summary, (o) => {
        return o.classification == 3;
      });
      return found && found.amount > 0;
    },
    hasBeverage() {
      const found = _.find(this.delivOrder.summary, (o) => {
        return o.classification == 2;
      });
      return found && found.amount > 0;
    },
  },
};
</script>

<style scoped>
.special-item {
  margin: 4px 0px 4px 8px;
  text-decoration: underline;
  font-size: 14px;
  font-weight: 600;
  color: #FF6D32;
}  
</style>
