<template lang="pug">
v-dialog(v-model="dialog", width="500", scrollable)
  v-form(@submit.prevent="submit")
    v-card(v-if="schedule")
      v-toolbar(flat, dense)
        .subtitle-2 {{ schedule.date | datetime('dddd M/D') }}
        v-spacer
        v-switch(
          v-model="schedule.status",
          label="Status",
          color="secondary",
          hide-details
        )
      v-card-text
        v-row(dense, wrap, v-if="schedule.status")
          v-col(
            cols="4",
            sm="3",
            v-for="(item, index) in schedule.blocks",
            :key="index"
          )
            v-btn(
              block,
              small,
              outlined,
              :color="item.status ? 'secondary' : 'grey'",
              @click="item.status = item.status == false"
            ) {{ item.mm | minutesToTime }}
      v-card-actions
        v-col
          .body-2.mb-1 {{ schedule | driverSchedule }}
          v-btn(block, color="secondary", type="submit") Save
</template>

<script>
import { EventBus } from "@/event-bus.js";
import { mapActions } from "vuex";

export default {
  data() {
    return {
      dialog: false,
      schedule: null,
      loading: false,
    };
  },
  methods: {
    ...mapActions(["updateDriverSchedule"]),
    open(data) {
      if (!data) return;
      this.schedule = JSON.parse(JSON.stringify(data));
      this.dialog = true;
    },
    async submit() {
      this.loading = true;
      const criteria = {
        user: this.schedule.user,
        date: this.schedule.date,
      };
      const params = {
        criteria: criteria,
        action: {
          $set: {
            status: this.schedule.status,
            blocks: this.schedule.blocks,
          },
        },
      };
      try {
        const res = await this.axios.post("/driver/schedules/update", params);
        this.updateDriverSchedule(res.data);
        this.dialog = false;
      } catch (err) {
        if (err && err.response) alert(err.response.data);
      }
      this.loading = false;
    },
  },
  mounted() {
    EventBus.$on("edit-driver-schedule", this.open);
  },
  destroyed() {
    EventBus.$off("edit-driver-schedule");
  },
};
</script>