<template lang="pug">
v-container
  v-row(wrap, no-gutters, align="center")
    v-col(cols="12", sm="4")
      .sl-title Account
    v-col(cols="12", sm="8")
      .d-flex.flex-row.flex-wrap
        v-btn.text-capitalize.mr-2.mb-2(
          v-for="(item, index) in items",
          :key="index",
          small,
          active-class="accent",
          :active="item.url === $route.path",
          :to="item.url",
          rounded,
          depressed
        ) {{ item.title }}
  v-divider.mb-2
  router-view
</template>

<script>
export default {
  data() {
    return {
      items: [
        { title: "Week", url: "/account/week" },
        { title: "Schedule", url: "/account/schedule" },
        { title: "Documents", url: "/account/documents" },
        { title: "Payouts", url: "/account/payouts" },
        { title: "Settings", url: "/account/payments" },
        { title: "Tax", url: "/account/tax" },
      ],
    };
  },
};
</script>
