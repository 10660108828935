<template lang="pug">
v-row(wrap dense)
  v-col(cols='12' sm='6' md='4' v-for='(item, index) in items' :key='index')
    Card(:delivOrder='item')
</template>

<script>
import Card from "./index";

export default {
  props: ["items"],
  components: { Card }
};
</script>