<template lang="pug">
.d-flex.flex-row.justify-center.align-center
  AppLinks
  a.link-item(href="https://help.selflane.com", target="_blank") Help Center
</template>

<script>
import AppLinks from "./AppLinks";

export default {
  components: { AppLinks },
};
</script>

<style scoped>
a {
  text-decoration: none;
}
.link-item {
  color: white;
  font-size: 17px;
  font-weight: 500;
  line-height: 30px;
  margin-left: 20px;
  margin-right: 20px;
  cursor: pointer;
}
</style>
