<template lang="pug">
v-row(dense)
  v-col(cols="6")
    v-btn(
      block,
      color="secondary",
      @click="handleAccept()",
      :loading="loading"
    ) Accept
  v-col(cols="6")
    Reject(:delivOrder="delivOrder")
</template>

<script>
import { mapActions } from "vuex";
import Reject from "./Reject";

export default {
  props: ["delivOrder"],
  components: { Reject },
  data() {
    return {
      loading: false,
    };
  },
  methods: {
    ...mapActions(["updateDelivOrder"]),
    async handleAccept() {
      if (!this.delivOrder) return;
      this.loading = true;
      const params = {
        criteria: { _id: this.delivOrder._id },
        action: { $set: { "driver.accept": Date.now() } },
      };
      try {
        const res = await this.axios.post("/delivOrders/update", params);
        this.updateDelivOrder(res.data);
      } catch (err) {
        if (err.response) alert(err.response.data);
      }
      this.loading = false;
    },
  },
};
</script>
