<template lang="pug">
v-bottom-sheet(v-model="sheet", v-if="visible")
  template(v-slot:activator="{ on }")
    v-btn.text-notransform(block, color="secondary", v-on="on") Yes, I Picked Up
  v-sheet.text-center.pa-3
    v-alert(type="error", v-if="isEarly") Order is due later, please don't pick it up too early.
    v-btn.my-5.text-notransform(
      color="secondary",
      @click="pickup()",
      :loading="loading"
    ) Confirm Picked Up
</template>

<script>
import { mapActions } from "vuex";

export default {
  props: ["delivOrder"],
  data() {
    return {
      sheet: false,
      loading: false,
    };
  },
  computed: {
    visible() {
      return (
        this.delivOrder &&
        this.delivOrder.status >= 0 &&
        !this.delivOrder.pickupTime
      );
    },
    isEarly() {
      const diff = (Date.now() - this.delivOrder.needed) / 1000 / 60; // in munites
      return diff > 60;
    },
  },
  methods: {
    ...mapActions(["updateDelivOrder"]),
    async pickup() {
      if (!this.delivOrder) return;
      this.loading = true;
      const params = { id: this.delivOrder._id, proxy: "Web" };
      try {
        const res = await this.axios.post("/delivOrders/pickup", params);
        this.updateDelivOrder(res.data);
        this.sheet = false;
      } catch (err) {
        if (err.response) alert(err.response.data);
      }
      this.loading = false;
    },
  },
};
</script>
