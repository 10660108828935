<template lang="pug">
v-container
  v-layout(row, justify-center)
    div.py-4(style="max-width: 400px; width: 100%")
      v-card
        v-card-title.grey.lighten-2 Reset Password
        v-card-text
          v-form(@submit.prevent="submit")
            v-text-field(
              label="E-mail",
              v-model.trim="email",
              :error-messages="emailErrors",
              @blur="$v.email.$touch()",
              required,
              prepend-icon="email"
            )
            div(v-if="isReset")
              v-text-field(
                label="Code",
                v-model="code",
                :error-messages="codeErrors",
                required,
                @blur="$v.code.$touch()",
                persistent-hint,
                hint="Found in email we just sent",
                prepend-icon="code"
              ) 
              v-text-field(
                name="password",
                type="password",
                label="New Password",
                v-model="password",
                :error-messages="passwordErrors",
                required,
                @blur="$v.password.$touch()",
                prepend-icon="lock_outline"
              ) 
            v-btn.white--text(
              block,
              type="submit",
              :disabled="loading || $v.$invalid",
              :loading="loading",
              color="indigo"
            ) submit
          span.caption.red--text(v-if="error") {{ error }}
</template>

<script>
import { validationMixin } from "vuelidate";
import { required, email, minLength } from "vuelidate/lib/validators";
import { EventBus } from "./../../../event-bus.js";

export default {
  mixins: [validationMixin],
  validations() {
    if (!this.isReset) {
      return {
        email: { required, email },
      };
    } else {
      return {
        email: { required, email },
        password: { required, minLength: minLength(6) },
        code: { required },
      };
    }
  },
  data() {
    return {
      email: "",
      password: "",
      code: "",
      loading: false,
      error: null,
      isReset: false,
    };
  },
  computed: {
    emailErrors() {
      const errors = [];
      if (!this.$v.email.$dirty) return errors;
      !this.$v.email.email && errors.push("Must be valid e-mail");
      !this.$v.email.required && errors.push("E-mail is required");
      return errors;
    },
    passwordErrors() {
      const errors = [];
      if (!this.$v.password.$dirty) return errors;
      !this.$v.password.required && errors.push("Password is required");
      !this.$v.password.minLength &&
        errors.push("Password should be at least 6 characters.");
      return errors;
    },
    codeErrors() {
      const errors = [];
      if (!this.$v.code.$dirty) return errors;
      !this.$v.code.required && errors.push("Code is required");
      return errors;
    },
  },
  methods: {
    submit() {
      if (this.isReset) this.reset();
      else this.forget();
    },
    forget() {
      this.error = null;
      const params = { criteria: { email: this.email } };
      this.loading = true;
      this.axios
        .post("/users/forgetPWD", params)
        .then(() => {
          this.loading = false;
          this.isReset = true;
        })
        .catch((err) => {
          this.loading = false;
          if (err.response) this.error = err.response.data;
        });
    },
    reset() {
      this.error = null;
      const params = {
        criteria: { email: this.email },
        code: this.code,
        password: this.password,
      };
      this.loading = true;
      this.axios
        .post("/users/resetPWD", params)
        .then(() => {
          this.loading = false;
          this.login();
        })
        .catch((err) => {
          this.loading = false;
          if (err.response) this.error = err.response.data;
        });
    },
    login() {
      this.loading = true;
      this.error = null;
      let redirect = { name: "Home" };
      this.$auth.login({
        headers: { "Content-Type": "application/json" },
        data: { email: this.email, password: this.password },
        redirect: redirect,
        rememberMe: true,
        success() {
          this.loading = false;
          EventBus.$emit("login");
        },
        error(err) {
          this.loading = false;
          if (err.response) {
            this.error = err.response.data;
          } else {
            this.error = "Failed";
          }
        },
      });
    },
  },
};
</script>
