<template lang="pug">
div
  .subtitle-2.mb-3 Serve with Excellence
  v-card(outlined, v-if="driver")
    v-toolbar(flat, dense)
      .subtitle-2 {{ driver.name }}
      v-spacer
      v-btn.text-capitalize(
        v-if="!is_edit_name",
        @click="handleEdit()",
        color="secondary",
        outlined,
        rounded,
        small
      )
        v-icon(small, left) mdi-pencil
        span Edit
    v-card-text
      table
        tbody
          tr
            td
              v-icon(left, small, color="grey") mdi-email
            td.text-right {{ driver.email }}
          tr
            td
              v-icon(left, small, color="grey") mdi-phone
            td.text-right {{ driver.phone | phone }}
          tr
            td
              v-icon(left, small, color="grey") mdi-map
            td.text-right {{ zones }}
          tr
            td
              v-icon(left, small, color="grey") mdi-card-account-details
            td.text-right
              span(v-if="hasAlcoholLicense") Alcohol Delivery (exp. {{ expiry }})
              v-btn(
                to="/account/documents",
                text,
                small,
                color="secondary",
                v-else
              ) Set up alcohol delivery
      .text-center.body-2(v-if="!has_delivery") A delivery manager will contact you soon to set up your account.
  EditDriver
</template>

<script>
import { mapGetters } from "vuex";
import EditDriver from "./EditDriver";
import { EventBus } from "@/event-bus";

export default {
  components: { EditDriver },
  data() {
    return {
      is_edit_name: false,
    };
  },
  computed: {
    ...mapGetters(["driver"]),
    has_delivery() {
      return this.driver && this.driver.deliv;
    },
    has_zone() {
      return this.driver && this.driver.zones && this.driver.zones.length > 0;
    },
    zones() {
      if (!this.driver || this.driver.zone) return "";
      return this.driver.zones.join(", ");
    },
    hasAlcoholLicense() {
      return (
        this.driver &&
        this.driver.alcohol_license &&
        this.driver.alcohol_license.status
      );
    },
    expiry() {
      if (this.hasAlcoholLicense)
        return (
          this.driver.alcohol_license.expiry.yyyy +
          "/" +
          this.driver.alcohol_license.expiry.mm +
          "/" +
          this.driver.alcohol_license.expiry.dd
        );
      return "";
    },
  },
  methods: {
    handleEdit() {
      EventBus.$emit("edit-driver");
    },
  },
};
</script>
